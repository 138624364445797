import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import BOS from './local/bos.json'
import ALB from './local/alb.json'


const i18n = createI18n({
    locale: localStorage.getItem('language'),
    messages:{
        BOS:BOS,
        ALB:ALB,
        
    }
  })
  



createApp(App).use(router).use(i18n).mount('#app')


