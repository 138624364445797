<template>
  <div class="home">
    <TablesBuilding/>
    <br>
    <DataBulding/>
    <br>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import TablesBuilding from '@/components/TablesBuilding.vue'
import DataBulding from '@/components/DataBulding.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    TablesBuilding, DataBulding,Footer,
  }
}
</script>
