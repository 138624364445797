<template>
  <nav v-if="!isLoginPage" class="navbar-container animate__animated animate__fadeInDown animate__delay-1s">
    <ul class="logo-container">
      <li class="logo"><img src="./assets/imagePropVertocal.png" width="150" height="200"></li>
    </ul>
    <ul class="rightmargin">
      <router-link to="/inbox" class="rlink"><li><i class="fa fa-inbox"></i> {{$t('inbox')}}</li></router-link>
      <router-link to="/settings" class="rlink"><li><i class="fa fa-cog"></i> {{$t('settings')}}</li></router-link>
      <router-link to="/help" class="rlink"><li><i class="fa fa-question" aria-hidden="true"></i> {{$t('help')}}</li></router-link>
    </ul>
  </nav>
  <hr>
  <div v-if="!isLoginPage" class="navbar animate__animated animate__fadeInUp animate__delay-1s">
    <div class="nav-item" @click="toggleBuildingSubnav" v-if="axiosResponse && axiosResponse.readOwner">
      <i class="fa fa-home"></i> <span>{{$t('building')}}</span>
    </div>
    <div v-show="showBuildingData" class="subnav animate__animated animate__fadeInLeft">
      <router-link to="/" class="subnav-link">- <span>{{$t('allbuildings')}}</span></router-link>
      <router-link to="/apartments" class="subnav-link">- <span>{{$t('floorSpaceinfo')}}</span></router-link>
      <router-link to="/commercialspaces" class="subnav-link">- <span>{{$t('commercialSpaceifno')}}</span></router-link>
      <router-link to="/garages" class="subnav-link">- <span>{{$t('garages')}}</span></router-link>
    </div>
    <div class="nav-item" @click="toggleOwnerSubnav" v-if="axiosResponse && axiosResponse.readOwner">
      <i class="fa fa-user"></i> <span>{{$t('owner')}}</span>
    </div>
    <div v-show="showOwnerSubnav" class="subnav animate__animated animate__fadeInLeft">
      <router-link to="/owner" class="subnav-link" v-if="axiosResponse && axiosResponse.readOwner">- <span>{{$t('allOwners')}}</span></router-link>
      <router-link to="/owner/dept" class="subnav-link" v-if="axiosResponse && axiosResponse.readDug">- <span>{{$t('debt')}}</span></router-link>
      <router-link to="/owner/add" class="subnav-link" v-if="axiosResponse && axiosResponse.writeOwner">- <span>{{$t('addOwner')}}</span></router-link>
    </div>
    <div class="nav-item" @click="toggleTenantsSubnav" v-if="axiosResponse && axiosResponse.readTenants">
      <i class="fa fa-users"></i> <span>{{$t('tenants')}}</span>
    </div>
    <div v-show="showTenatsSubnav" class="subnav animate__animated animate__fadeInLeft">
      <router-link to="/tenants" class="subnav-link" v-if="axiosResponse && axiosResponse.readTenants">- <span>{{$t('allTenants')}}</span></router-link>
      <router-link to="/addtenants" class="subnav-link" v-if="axiosResponse && axiosResponse.writeTenants">- <span>{{$t('addTenants')}}</span></router-link>
    </div>
    <router-link to="/pdftamplate" exact class="nav-item">
      <i class="fa fa-file-pdf-o"></i> <span>{{$t('generateContract')}}</span>
    </router-link>
  </div>
  <div class="main-content">
    <router-view/>
  </div>
</template>

<script>
import { Service, Auth } from "@/services";

export default {
  data() {
    return {
      showOwnerSubnav: false,
      showTenatsSubnav: false,
      showBuildingData: false,
      axiosResponse: null,
    };
  },
  computed: {
    isLoginPage() {
      return this.$route.path === '/login';
    },
  },
  methods: {
    toggleOwnerSubnav() {
      this.showOwnerSubnav = !this.showOwnerSubnav;
    },
    toggleTenantsSubnav() {
      this.showTenatsSubnav = !this.showTenatsSubnav;
    },
    toggleBuildingSubnav() {
      this.showBuildingData = !this.showBuildingData;
    },
    async getMainUsersAndRolls() {
      try {
        let tokendata = JSON.parse(localStorage.getItem("user"));
        if (tokendata && tokendata.email) {
          const response = await Service.get(`${process.env.VUE_APP_API_URL}/userroll`, {
            params: { email: tokendata.email },
          });
          this.axiosResponse = response.data;
        }
      } catch (error) {
        console.error("Error fetching user roles:", error);
      }
    },
        async getAuthToken() {
      try {
        const response = await Service.get(`${process.env.VUE_APP_API_URL}/authtoken`);
      } catch (error) {
        console.error("Error fetching data", error.response.status);
        if(error.response.status){
           localStorage.removeItem('user');
           this.$router.push({ path: "/login" }).then(() => location.reload())
        }
      }
    }
  },
  created() {
    this.getMainUsersAndRolls();
     this.getAuthToken();
  },
};

function setLanguage() {
  const data = localStorage.getItem("language");
  if (data === null) {
    localStorage.setItem("language", "BOS");
  }
}
setLanguage();
</script>

<style>
body {
  margin: 0;
}

.rlink {
  color: black;
}
.rlink:hover {
  color: #0172ad;
}

.nav-item span,
.subnav-link span {
  font-size: 20px; /* Adjust the font size as needed */
}

.navbar {
  height: 100%;
  width: 200px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #435363;
  overflow-x: hidden;
  padding-top: 20px;
  color: #ecf0f1;
}

.navbar .nav-item,
.nav-link {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 18px;
  color: #ecf0f1;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar .nav-item i,
.nav-link i {
  margin-right: 10px;
}

.navbar .nav-item:hover,
.nav-link:hover {
  background-color: #34495e;
}

.subnav {
  padding-left: 30px;
}

.subnav-link {
  padding: 4px 8px;
  font-size: 16px;
  display: block;
  color: #ecf0f1;
  text-decoration: none;
}

.subnav-link:hover {
  background-color: #34495e;
}

.rightmargin {
  margin-right: 20px !important;
}

.logo {
  margin-left: 30px;
}

.navbar-container {
  margin-left: 200px;
}

.main-content {
  margin-left: 200px;
}

@media (max-width: 768px) {
  .navbar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .navbar .nav-item,
  .nav-link {
    font-size: 16px;
    padding: 8px 10px;
  }
  .subnav {
    padding-left: 15px;
  }
  .navbar-container,
  .main-content {
    margin-left: 0;
  }
  .logo-container {
    display: flex;
    justify-content: center;
  }
  .logo {
    margin-left: 0;
  }
}
</style>
