<template>
  <div class="animate__animated animate__fadeInUp animate__delay-1s">
    <article class="datas">
      <details>
        <summary role="button">{{ $t('addNewBuilding') }}</summary>
        <fieldset class="grid">
          <label>{{ $t('nameOfBuilding') }}:<b class="important">*</b>
            <input autocomplete="off" required type="text" v-model="naziv_zgrade" name="text" aria-label="Text" />
          </label>
          <label>{{ $t('numberOfApartments') }}:<b class="important">*</b>
            <input autocomplete="off" required type="number" v-model="broj_stanova" name="text" aria-label="Text"  v-on:keypress="NumbersOnly"/>
          </label>
          <label>{{ $t('floorSpace') }} (&#13217;):<b class="important">*</b>
            <input autocomplete="off" required type="number" v-model="povrsina_stanova" name="text" aria-label="Text" v-on:keypress="NumbersOnly" />
          </label>
        </fieldset>
        <fieldset class="grid">
          <label>{{ $t('numberOfGarages') }}:<b class="important">*</b>
            <input autocomplete="off" required type="number" v-model="broj_garaza" name="text" aria-label="Text" v-on:keypress="NumbersOnly"/>
          </label>
          <label>{{ $t('numberOfCommercialSpaces') }}:<b class="important">*</b>
            <input autocomplete="off" required type="number" v-model="broj_poslovnih_prostora" name="text" aria-label="Text" v-on:keypress="NumbersOnly"/>
          </label>
          <label>{{ $t('commercialSpace') }} (&#13217;):<b class="important">*</b>
            <input autocomplete="off" required type="number" name="text" v-model="povrsina_poslovnih_prostora" aria-label="Text"  v-on:keypress="NumbersOnly"/>
          </label>
        </fieldset>
        <fieldset class="grid">
          <label>{{ $t('totalArea') }} (&#13217;):<b class="important">*</b>
            <input autocomplete="off" required type="number" name="text" v-model="ukupna_povrsina" aria-label="Text" v-on:keypress="NumbersOnly"/>
          </label>
          
          <label>{{ $t('authority') }}:<b class="important">*</b>
            <input autocomplete="off" required type="text" name="text" v-model="ovlasteni_presednik" aria-label="Text" />
          </label>
          <label>{{ $t('city') }}:
            <input autocomplete="off" required type="text" v-model="grad" name="text" aria-label="Text" />
          </label>
        </fieldset>
        

<div class="addAprtmantsBroder">
<div class="addAprtmants">
<h4>{{ $t('generateNewApartmants') }}</h4>


          <label>
    <input type="checkbox" name="english" v-model="blokovichecked" checked />
      {{ $t('iwantnumbersbyblocks') }} <div class="tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
  <span class="tooltiptext">{{ $t('shapreofapartmantsnumber') }}</span>
</div>
    
  </label>

        <fieldset class="grid">
<div v-if="blokovichecked">
            <label class="input-with-icon">{{ $t('numberOfLamelas') }}:
        <input autocomplete="off" required type="number" v-model="broj_lamela" name="text" aria-label="Text" max="10" disabled/>
        <div class="icon-container">
            <i class="fa fa-chevron-up" aria-hidden="true" @click="addlamela()"></i>
            <i class="fa fa-chevron-down" aria-hidden="true" @click="removelamela()"></i>
        </div>
    </label>
</div>


    <div v-if="blokovichecked">
          <div  v-for="(input, index) in lamelas" :key="index" >
            <label>{{ $t('nameForLamelas') }} <b>{{ index + 1 }}</b>:<b class="important">*</b>
              <input type="text" v-model="lamelas[index]" @input="generateAdditionalLamelas" />
            </label>
          </div>
    </div>
        </fieldset>




<!--  -->
          <div v-for="(blok, index) in blokovi" :key="index">
            <label>{{ $t('numberOfApartmantsInBloks') }}<b>{{ blok }}</b>:<b class="important">*</b>
              <input type="number" v-model="stanovi[index]"  v-on:keypress="NumbersOnly"/>
            </label>
          </div>
          <label>
    <input type="checkbox" name="english" v-model="desetice" />
    {{ $t('iwant10inmyapartmants') }}  <div class="tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
  <span class="tooltiptext">{{ $t('apartmantswillnotinclude') }}</span>
</div>
  </label>


<div>
          <label>
    <input type="checkbox" name="english" v-model="outchecked" />
    {{ $t('iwanttokicksomenumberout') }} <div class="tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
     <span class="tooltiptext">{{ $t('exampleofoutnumbers') }}</span>
    </div>
  </label>
</div>

<div>
          <label>
    <input type="checkbox" name="english" v-model="startfromchecked" />
    {{ $t('iwanttostartfrommynumber') }} <div class="tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
     <span class="tooltiptext">{{ $t('exampleofstatringfromynumber') }}</span>
    </div>
  </label>
</div>

<br>
<div v-if="startfromchecked">
                      <label>{{ $t('startfromnumber') }}:    <div class="tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
            <span class="tooltiptext"> {{ $t('exampleofstatringfromynumber1') }}</span>
              </div>
              <input  required type="number" v-model="startfromnumber"  />
            </label>
</div>
<br>
<div v-if="outchecked">
            <label>{{ $t('numberyouwanttokick') }}:    <div class="tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>
            <span class="tooltiptext"> {{ $t('seperetwithcoma') }}</span>
              </div>
              <input  v-model="outnumbers" placeholder="2,3" />
            </label>
            
</div>
</div>
</div>
<br>
<!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  --><!--  -->
<!-- GENERIRANJE LOKALA -->
<div class="addLokBroder">
<div class="addAprtmants">
<h4>{{ $t('generatecomercialspace') }}</h4>
<div v-if="lamelas">
          <label>
    <input type="checkbox" name="english" v-model="blokovilokchecked" />
      {{ $t('iwantnumberbylamelas') }} <div class="tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>

  <span class="tooltiptext">{{ $t('generateblockwillbeshapeof') }}</span>
</div>
  </label>
  <div v-if="blokovilokchecked">
          <div v-for="(blok, index) in blokovi" :key="index">
            <label>{{ $t('numberOfComSpaceInBloks') }} <b>{{ blok }}</b>:<b class="important">*</b>
              <input type="number" v-model="lokali[index]" v-on:keypress="NumbersOnly" />
            </label>
          </div>
  </div>
</div>
</div>
</div>
<!-- ============= -->
<br>
<!-- GENERIRANJE GARAZE -->
<div class="addGarageBroder">
<div class="addAprtmants">
<h4>{{ $t('generategarages') }}</h4>
<div v-if="lamelas">
          <label>
    <input type="checkbox" name="english" v-model="combinearraygarag" />
      {{ $t('iwantnumberbylamelasgar') }} <div class="tooltip"><i class="fa fa-info-circle" aria-hidden="true"></i>

  <span class="tooltiptext">{{ $t('generateblockwillbeshapeofGar') }}</span>
</div>
  </label>
  <div v-if="combinearraygarag">
          <div v-for="(blok, index) in blokovi" :key="index">
            <label>{{ $t('numberOfGaragInBloks') }} <b>{{ blok }}</b>:<b class="important">*</b>
              <input type="number" v-model="garg[index]" v-on:keypress="NumbersOnly" />
            </label>
          </div>
  </div>
</div>
</div>
</div>
<!-- ============= -->
<br>
        <fieldset class="grid">
          <label>{{ $t('contact') }}:<b class="important">*</b>
            <input autocomplete="off" required type="text" name="text" v-model="kontakt_informacije" aria-label="Text" v-on:keypress="NumbersOnly" />
          </label>
        </fieldset>

        <div class="right-div">
          <div class="button-div">
            <button style="font-size:21px" class="pico-background-green-450" @click="openDialog()">
              <i class="fa fa-plus-square" aria-hidden="true"></i> {{ $t('add') }}
            </button>
          </div>
        </div>
      </details>

      <dialog :open="dialogOpen">
        <article>
          <span v-if="!hideDialogData" aria-busy="true">{{ $t('addingDataToDataBase') }}...</span>
          <div v-if="hideDialogData">
            <h2>{{ $t('addNewBuilding') }}</h2>
            <p>{{ $t('areYouSureYouWantToAdd') }}?<br>
              {{ $t('beforeAddingPleaseCheckOneMoreTime') }}!
            </p>
          </div>
          <footer v-if="hideDialogData">
            <button class="secondary" @click="closeDialog()">{{ $t('cancel') }}</button>
            <button @click="postData()">{{ $t('confirm') }}</button>
          </footer>
        </article>
      </dialog>
    </article>
       <!-- <h2>{{ this.lamelas }}</h2>
    <h2>stanovi{{ this.stanovi }}</h2> -->
    <!-- <h2>garag{{ this.garg }}</h2> -->
    <!-- <h2>blokovi{{ this.blokovi }}</h2>
    <h2>outchecked{{ this.outchecked }}</h2>
    <h2>{{ this.combinearray }}</h2>
    <h2>{{this.desetice}}</h2> -->
    <!-- <h2>blokgagcheck {{this.combinearraygarag}}</h2>
     <h2>combinearraygarag {{this.combinearraygarag}}</h2> -->
    <!-- <h2>{{this.outnumbers}}</h2>
    <h2>{{this.combinearraylokali}}</h2> -->

  </div>
</template>

<script>
import sleep from 'sleep-promise';
import { Service } from '@/services';
import { NumbersOnly } from '../utils/globalFunctions';

export default {
  name: 'DataBulding',
  props: {
    msg: String
  },
  data() {
    return {
      naziv_zgrade: null,
      broj_stanova: null,
      povrsina_stanova: null,
      broj_garaza: null,
      broj_poslovnih_prostora: null,
      povrsina_poslovnih_prostora: null,
      ukupna_povrsina: null,
      ovlasteni_presednik: null,
      kontakt_informacije: null,
      grad: null,
      broj_lamela: 1,
      dialogOpen: false,
      hideDialogData: true,
      desetice:false,
      blokovichecked:false,
      blokovilokchecked:false,
      blokoviGarchecked:false,
      outchecked:false,
      startfromchecked:false,
      outnumbers:null,
      lamelas: null,
      blokovi: [], // Dodat niz za čuvanje naziva blokova (A, B, C)
      stanovi: [],
      lokali: [],// Dodat niz za čuvanje broja stanova po blokovima
      garg: [],
      combinearray:null,
      combinearraylokali:null,
      combinearraygarag:null,
      startfromnumber:null
    };
  },
  watch: {
    broj_lamela(newVal) {
      this.generateLamelas(newVal);
    },
        broj_stanova(value) {
      if (value < 0) this.broj_stanova = 0;
    },
    povrsina_stanova(value) {
      if (value < 0) this.povrsina_stanova = 0;
    },
    broj_garaza(value) {
      if (value < 0) this.broj_garaza = 0;
    },
    broj_poslovnih_prostora(value) {
      if (value < 0) this.broj_poslovnih_prostora = 0;
    },
    povrsina_poslovnih_prostora(value) {
      if (value < 0) this.povrsina_poslovnih_prostora = 0;
    },
    ukupna_povrsina(value) {
      if (value < 0) this.ukupna_povrsina = 0;
    }




  },
  methods: {
    NumbersOnly,
    addlamela(){
        this.broj_lamela =  this.broj_lamela+1;
    },
    removelamela(){
      if(this.broj_lamela>1)
      this.broj_lamela =  this.broj_lamela-1;
    },
    generateLamelas(count) {
      this.lamelas = Array(count).fill('');
      this.blokovi = [];
      this.stanovi = [];
    },
    generateAdditionalLamelas() {
      // Generiše nazive blokova na osnovu unetih lamela
      this.blokovi = this.lamelas.map(lamela => `${lamela}`);
      // Resetuje unos broja stanova po blokovima
      this.stanovi = Array(this.lamelas.length).fill(null);
    },
    openDialog() {
      this.dialogOpen = true;
    },
    closeDialog() {
      this.dialogOpen = false;
    },
    combineAr(){
          this.combinearray = this.blokovi.map((blok, index) => `${blok}_${this.stanovi[index]}`);
    },
        combineArLok(){
          this.combinearraylokali = this.blokovi.map((blok, index) => `${blok}_${this.lokali[index]}`);
    },
            combineArGarg(){
          this.combinearraygarag = this.blokovi.map((blok, index) => `${blok}_${this.garg[index]}`);
    },
            ifNothing(){
          if (this.stanovi.length === 0 || this.blokovi.length === 0){
            console.log("Stanovi: ", this.stanovi.length,this.blokovi.length);
            this.blokovichecked = false;
            let changestring =  'A_' + this.broj_stanova;
            let myarray =changestring.split(" ");
            this.combinearray = myarray;

          }else{
                this.combineAr();
          }
    },
                ifNothinglok(){
          if (this.lokali.length === 0 || this.blokovi.length === 0){
            this.blokovilokchecked = false;
            let changestring =  'A_' + this.broj_poslovnih_prostora;
            let myarray =changestring.split(" ");
            this.combinearraylokali = myarray;


          }else{
                this.combineArLok();
          }
    },
            ifNothingGarag(){
          if (this.garg.length === 0 || this.blokovi.length === 0){
            this.blokoviGarchecked = false;
            let changestring =  'A_' + this.broj_garaza;
            let myarray =changestring.split(" ");
            this.combinearraygarag = myarray;

          }else{
                this.combineArGarg();
          }
    },
  
    async postData() {
      let isAnyValueNull = false;
      // Proverava da li su svi podaci popunjeni
     
        if (this.naziv_zgrade === null && this.broj_stanova == null && this.povrsina_stanova  == null && this.broj_garaza == null && this.broj_poslovnih_prostora == null && this.povrsina_poslovnih_prostora == null && this.ukupna_povrsina == null && this.grad == null  && this.kontakt_informacije == null) {
          
        }else{isAnyValueNull = false;}
  
        this.ifNothing();

        this.ifNothinglok();

        this.ifNothingGarag();
        
      if (isAnyValueNull) {
        alert("Molimo vas ispunite sve podatke");
      } else {
        try {
          // Kreira objekat sa podacima za slanje
          let postData = {
            naziv_zgrade: this.naziv_zgrade,
            broj_stanova: this.broj_stanova,
            povrsina_stanova: this.povrsina_stanova,
            broj_garaza: this.broj_garaza,
            broj_poslovnih_prostora: this.broj_poslovnih_prostora,
            povrsina_poslovnih_prostora: this.povrsina_poslovnih_prostora,
            ukupna_povrsina: this.ukupna_povrsina,
            ovlasteni_presednik: this.ovlasteni_presednik,
            kontakt_informacije: this.kontakt_informacije,
            grad: this.grad,
            arrayofblocks: this.combinearray,
            desetice: this.desetice,
            blokovichecked: this.blokovichecked,
            outchecked: this.outchecked,
            outnumbers: this.outnumbers,
            blokovilokchecked:this.blokovilokchecked,
            combinearraylokali: this.combinearraylokali,
            startfromnumber: this.startfromnumber,
            blokoviGarchecked: this.blokovilokchecked,
            combinearraygarag:this.combinearraygarag

            



          };

          // Poziva servis za slanje podataka na server
          let axiosData = await Service.post(`${process.env.VUE_APP_API_URL}/addzgrade`, postData);
          this.hideDialogData = false;
          await sleep(1750);
          this.closeDialog();
          location.reload(); 
        } catch (error) {
          console.error("Error while posting data:", error);
        }
      }
    }
  }
}
</script>

<style>
  .important{
    color: brown;
  }
       .input-with-icon {
            position: relative;
            display: inline-block;
        }

        .input-with-icon input {
            padding-right: 2.5em; /* Space for the icons */
        }

        .input-with-icon .icon-container {
            position: absolute;
            right: 0.5em;
            top: 55%;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .input-with-icon .fa-chevron-up,
        .input-with-icon .fa-chevron-down {
            cursor: pointer;
            margin: 2px 0;
        }
h4{
  text-align: center;
}
.addAprtmantsBroder{
  border: 1px solid;
  border-color: #052940;
  border-radius: 15px;
}

.addLokBroder{
  background-color: rgb(247, 252, 252);
  border: 1px solid;
  border-color: #052940;
  border-radius: 15px;
}

.addGarageBroder{
  background-color: azure;
  border: 1px solid;
  border-color: #052940;
  border-radius: 15px;
}



.addAprtmants{
  margin: 10px;
  padding: 25px;
}
.datas {
  margin: auto;
  padding: 10px;
  width: 99%;
  border: 1px solid;
  border-color: #052940;
}

input[type="checkbox"]:checked {
    background-color: #02659A;
    border-color: #02659A;
}

.button-div {
  display: flex;
  justify-content: space-between;
  max-width: 280px;
}

input:focus,
input:valid {
  background-color: #e8f0fe;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  font-weight: small;
  background-color: #052940;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  font-weight: small;
}
</style>
