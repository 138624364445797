<template>
  <article class="art animate__animated animate__fadeInUp" :class="{ 'artd': editMode }">
    <div class="table-containe tabel-shadow">
      <div class="overflow-auto">
        <h6>{{$t('buildingTabel')}}</h6>
        <div class="alert animate__animated animate__fadeInUp animate__delay-1s" v-show="editMode" >
          <span class="closebtn" @click="hideAlert">&times;</span> 
          <strong>{{$t('youAreInEditMode')}}! </strong>{{$t('afterChangeDataCanNotBeRetrived')}}.
        </div>
        <table class="striped" :class="{ 'danger-table animate__animated animate__fadeInUp': editMode }">
          <thead>
            <tr style="font-size:19px; font-weight: bold;">
              <th>Id</th>
              <th>{{$t('nameOfBuilding')}}</th>
              <th>{{$t('numberOfApartments')}}</th>
              <th>{{$t('floorSpace')}} (㎡)</th>
              <th>{{$t('numberOfGarages')}}</th>
              <th>{{$t('numberOfCommercialSpaces')}}</th>
              <th>{{$t('commercialSpace')}} (㎡)</th>
              <th>{{$t('totalArea')}} (㎡)</th>
              <th>{{$t('authority')}}</th>
              <th>{{$t('city')}}</th>
              <th>{{$t('contact')}}</th>
            </tr>
          </thead>
          <tbody class="pico-background-azure-50">
            <tr v-for="(row, rowIndex) in axiosData" :key="rowIndex">
              <td>{{ row.id_zgrade }}</td>
              <td :contenteditable="editMode" @input="updateData($event, row, 'naziv_zgrade')">{{ row.naziv_zgrade }}</td>
              <td :contenteditable="editMode" @input="updateData($event, row, 'broj_stanova')" @keypress="allowOnlyNumbers($event, 'broj_stanova')">{{ row.broj_stanova }}</td>
              <td :contenteditable="editMode" @input="updateData($event, row, 'povrsina_stanova')" @keypress="allowOnlyNumbers($event, 'povrsina_stanova')">{{ row.povrsina_stanova }}</td>
              <td :contenteditable="editMode" @input="updateData($event, row, 'ukupan_broj_garaza')" @keypress="allowOnlyNumbers($event, 'ukupan_broj_garaza')">{{ row.ukupan_broj_garaza }}</td>
              <td :contenteditable="editMode" @input="updateData($event, row, 'broj_poslovnih_prostora')" @keypress="allowOnlyNumbers($event, 'broj_poslovnih_prostora')">{{ row.broj_poslovnih_prostora }}</td>
              <td :contenteditable="editMode" @input="updateData($event, row, 'povrsina_poslovnih_prostora')" @keypress="allowOnlyNumbers($event, 'povrsina_poslovnih_prostora')">{{ row.povrsina_poslovnih_prostora }}</td>
              <td :contenteditable="editMode" @input="updateData($event, row, 'ukupna_povrsina')" @keypress="allowOnlyNumbers($event, 'ukupna_povrsina')">{{ row.ukupna_povrsina }}</td>
              <td :contenteditable="editMode" @input="updateData($event, row, 'ovlasteni_presednik')">{{ row.ovlasteni_presednik }}</td>
              <td :contenteditable="editMode" @input="updateData($event, row, 'grad')">{{ row.grad }}</td>
              <td :contenteditable="editMode" @input="updateData($event, row, 'kontakt_informacije')">{{ row.kontakt_informacije }}</td>
            </tr>
          </tbody>
        </table>
        <div class="button-div">
          <button @click="toggleEditMode" style="font-size:21px" class="pico-background-azure-700">
            <i class="fa fa-edit"></i> {{ editMode ? $t('exitEditMode') : $t('enterEditMode') }}
          </button>
          <button @click="putChangedData" style="font-size:21px;  color: white;" v-if="editMode" class="pico-background-green-450">
            <i class="fa fa-exchange" aria-hidden="true"></i> {{$t('change')}}
          </button>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import axios from "axios";
import { Service } from '@/services'

export default {
  name: 'TablesBuilding',
  props: {
    msg: String
  },
  data() {
    return {
      axiosData: null,
      editMode: false,
      originalData: null, 
      changes: [],
      NumbersField: ['id_zgrade', 'broj_stanova', 'ukupan_broj_garaza', 'povrsina_stanova', 'broj_poslovnih_prostora', 'ukupna_povrsina', 'povrsina_poslovnih_prostora']
    };
  },
  methods: {
    async getTabelData() {
      try {
        const response = await Service.get(`${process.env.VUE_APP_API_URL}/`);
        this.axiosData = response.data;
        this.originalData = JSON.parse(JSON.stringify(response.data)); 
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    updateData(event, row, field) {
  const newValue = event.target.textContent;
  const oldValue = row[field];

  // Provjera da li se polje nalazi u NumbersField arrayu
  const isNumberField = this.NumbersField.includes(field);
  const parsedValue = isNumberField ? parseFloat(newValue) : newValue;

  const existingIndex = this.changes.findIndex(change => change.id === row.id_zgrade);

  if (existingIndex !== -1) {
    // Ako postoji objekat sa istim id-om, zamijeni ga novim
    this.changes[existingIndex] = {
      id: row.id_zgrade,
      field: field,
      oldValue: oldValue,
      newValue: parsedValue
    };
  } else {
    // Ako ne postoji objekat sa istim id-om, dodaj novi objekat
    const change = {
      id: row.id_zgrade,
      field: field,
      oldValue: oldValue,
      newValue: parsedValue
    };
    this.changes.push(change);
  }

  row[field] = newValue;
},

    allowOnlyNumbers(event, fieldName) {
      const allowedFields = this.NumbersField;
      if (allowedFields.includes(fieldName)) {
        const keyCode = event.keyCode;
        if (keyCode < 48 || keyCode > 57) {
          event.preventDefault();
        }
      }
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    async putChangedData() {

      try {
        const response = await Service.put(`${process.env.VUE_APP_API_URL}/updatezgrade`, this.changes[this.changes.length - 1]);
        console.log("Data updated:", this.changes[this.changes.length - 1]);
         location.reload();
      } catch (error) {
        console.error("Error updating data:", error);
      }
    },
    hideAlert() {
      const alert = document.querySelector(".alert");
      if (alert) {
        alert.style.display = "none";
      }
    }
  },
  created() {
    this.getTabelData();
  }
}
</script>

<style scoped>
.art {
  margin: auto;
  padding: 10px;
  width: 99%;
  border: 1px solid;
  border-color: #052940;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.alert {
  border-radius: 5px;
  padding: 20px;
  background-color: #F06048;
  color: white;
  margin-bottom: 10px;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}
.danger-table td {
  background-color: #F9DCD2;
}
.artd {
}
.button-div {
  display: flex;
  justify-content: space-between;
  max-width: 420px;
}
</style>
