<template>

    <div class="animate__animated animate__fadeInUp">
      <footer class="custom-footer">
        PropGuard all right recived 
    <a href="https://www.facebook.com"><i class="fa fa-facebook-official" style="color:black" aria-hidden></i></a> |  
    <a href="https://www.instagram.com"><i class="fa fa-instagram" style="color:black" aria-hidden></i></a> 
      <p>v1.1</p>
    </footer>
    </div>

  </template>
  
  <script>
  export default {
    name: 'Footer',
  }
  </script>
  
  <style scoped>
  .custom-footer {
    margin: auto;
     padding: 10px;
    width: 99%;
    border-radius: 5px;
    padding: 10px; 
    text-align: center; 
  }

  </style>
  