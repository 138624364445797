import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { Auth } from '@/services'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/owner',
    name: 'ower',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "owner" */ '../views/Owner.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/LogIn.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Settings" */ '../views/Settings.vue')
  },
  {
    path: '/paymantdata',
    name: 'paymantdata',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "paymantdata" */ '../views/PaymantData.vue')
  },
  {
    path: '/tenants',
    name: 'tenants',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Tenants" */ '../views/Tenants.vue')
  },
  {
    path: '/owner/add',
    name: 'owneraddtabel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "OwnerAddTabel" */ '../views/OwnerAddTabel.vue')
  },
  {
    path: '/owner/dept',
    name: 'ownerdept',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "DeptView" */ '../views/DeptView.vue')
  },
  {
    path: '/pdftamplate',
    name: 'pdftamplate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "PdfTamplate" */ '../views/PdfTamplate.vue')
  },
  {
    path: '/addtenants',
    name: 'AddTenants',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "addtenants" */ '../views/AddTenants.vue')
  },
  {
    path: '/logs',
    name: 'Logs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "logs" */ '../views/Logs.vue')
  },
  {
    path: '/garages',
    name: 'garages',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "apartments" */ '../views/Garages.vue')
  },
  {
    path: '/apartments',
    name: 'apartments',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "apartments" */ '../views/Apartments.vue')
  },
  {
    path: '/commercialspaces',
    name: 'commercialspaces',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "apartments" */ '../views/CommercialSpaces.vue')
  },
  {
    path: '/help',
    name: 'help',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "apartments" */ '../views/Help.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
  
})

router.beforeEach((to, from, next)=>{
  const publicPages=["/login"];
  const loginPage= !publicPages.includes(to.path); 
  const  user = Auth.getUser();
  if(loginPage && !user){
     next('/login');
     return

  }


  next();
})

export default router
